@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');
@import "assets/font-awesome-pro-6/css/all.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h-fit {
  height: fit-content ! important;
}

.c-pointer {
  cursor: pointer ! important;
}

.jodit-container {
  @import "~bootstrap/dist/css/bootstrap";
}

@mixin z-x {
  @for $i from -100 through 100 {
    .z-#{$i} { z-index: #{$i} }
  }
}

@include z-x;